import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components1/bio'
import Layout from '../components1/layout'
import Seo from '../components1/seo'
import { rhythm } from '../utils/typography'
import blogStyles from "./blog.module.css"
const Blog = (props) => {
  
    const { data } = props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={props.location} title={siteTitle}>
        <Seo
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        
        <ol className={blogStyles.blogCards}>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <li className={blogStyles.blogCard}>
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
            </li>
          )
        })}
        </ol>
      </Layout>
    )
  
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC},  filter: {fields: {slug: {ne: "/home/home/"}}} ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
